import MDinput from '@/components/MDinput';
import { validatorEmail, validatorRequire, validatorRequireMultipleSelect } from '@/utils/validators';
import Select from '@/components/DSE/Select';

export function getFormDefinition(vue) {
  return {
    id: {
      type: null,
      defaultValue: undefined
    },
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    description: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255
      }
    },
    code: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true,
        caption: 'login.code'
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    email: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 64,
        required: true,
        caption: 'common.email'
      },
      rules: [
        {
          validator: validatorRequire
        },
        {
          validator: validatorEmail
        }
      ]
    },
    avatar: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        caption: 'user.avatar'
      }
    },
    roles: {
      type: Select,
      defaultValue: [],
      props: {
        caption: 'user.roles',
        valueField: 'id',
        labelField: 'name',
        multiple: true,
        loading: vue.$store.getters['role/isLoading'],
        options: vue.$store.getters['role/loadedItems'],
        required: true
      },
      rules: [
        {
          validator: validatorRequireMultipleSelect
        }
      ]
    }
  };
}

export function createItemFromFormData(vue, form) {
  const user = {
    ...form,
    domainID: vue.$store.getters.domainID,
    roles: []
  };
  if (form.id) {
    user['id'] = form.id;
  }
  form.roles.forEach(roleID => {
    const role = vue.$store.getters['role/getLoadedItemByID'](roleID);
    user.roles.push(role);
  });
  return user;
}

export function createFormDataFromItem(vue, user) {
  const form = {
    ...user
  };
  form.roles = [];
  user.roles.forEach(role => {
    form.roles.push(role.id);
  });
  return form;
}
